import _GITHUB_ALL_PUBLIC_REPOS from '~/data/external/github-all-public-repos.json'
import _GITHUB_CLI_DOCS_CONFIG from '~/data/external/github-cli-docs-config.json'
import _GITHUB_CLI_DOCS_MASTER from '~/data/external/github-cli-docs-master.json'
import _GITHUB_CLI_DOCS_MASTER_LAST_COMMIT from '~/data/external/github-cli-docs-master-last-commit.json'
import _GITHUB_CLI_DOCS_STABLE from '~/data/external/github-cli-docs-stable.json'
import _GITHUB_CLI_DOCS_STABLE_LAST_COMMIT from '~/data/external/github-cli-docs-stable-last-commit.json'
import _GITHUB_CLI_RELEASES from '~/data/external/github-cli-releases.json'
import _GITHUB_DESKTOP_LATEST_RELEASE from '~/data/external/github-desktop-latest-release.json'
import _LEVER_POSTINGS from '~/data/external/lever-postings.json'
import _ASHBY_POSTINGS from '~/data/external/ashby-postings.json'

import { RepoDocsConfig } from './types'


export const GITHUB_ALL_PUBLIC_REPOS = _GITHUB_ALL_PUBLIC_REPOS
export const GITHUB_CLI_DOCS_CONFIG = _GITHUB_CLI_DOCS_CONFIG as RepoDocsConfig
export const GITHUB_CLI_DOCS_MASTER_LAST_COMMIT = _GITHUB_CLI_DOCS_MASTER_LAST_COMMIT
export const GITHUB_CLI_DOCS_MASTER_README = _GITHUB_CLI_DOCS_MASTER.README
export const GITHUB_CLI_DOCS_STABLE_LAST_COMMIT = _GITHUB_CLI_DOCS_STABLE_LAST_COMMIT
export const GITHUB_CLI_DOCS_STABLE_README = _GITHUB_CLI_DOCS_STABLE.README
export const GITHUB_CLI_RELEASES = _GITHUB_CLI_RELEASES
export const GITHUB_DESKTOP_LATEST_RELEASE = _GITHUB_DESKTOP_LATEST_RELEASE
export const LEVER_POSTINGS = _LEVER_POSTINGS
export const ASHBY_POSTINGS = _ASHBY_POSTINGS.results
