import { useState, useEffect } from 'react'
import { isWindows, isMacOs, isIOS, isDesktop } from 'react-device-detect'

export enum OS {
  Mac = 'Mac',
  Windows = 'Windows',
  Linux = 'Linux',
  iOS = 'iOS',
  Other = 'Other'
}

export const useOSName = (initialPlatform = OS.Other) => {
  const [osName, setOSName] = useState<OS>(initialPlatform)

  useEffect(() => {
    if (isMacOs) {
      setOSName(OS.Mac)
    } else if (isWindows) {
      setOSName(OS.Windows)
    } else if (isDesktop) {
      setOSName(OS.Linux)
    } else if (isIOS) {
      setOSName(OS.iOS)
    } else {
      setOSName(OS.Other)
    }
  }, [])

  return osName
}
